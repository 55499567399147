import styled from "@emotion/styled";
import {
  List,
  ListItem,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const PrivacyDataUse = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Typography
        variant="h3"
        onClick={() => navigate("/capture")}
        style={{ cursor: "pointer" }}
      >
        Cañía
      </Typography>
      <nav aria-label="secondary mailbox folders">
        <b>Registro de resultado de prueba de autodiagnóstico de COVID-19</b>
        <List>
          <ListItem disablePadding>
            <ListItemText
              primary="Paso 1 de 4"
              secondary="Elección de tipo de documento"
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              primary="Paso 2 de 4"
              secondary="Capturar fotografía"
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText
              primary="Paso 3 de 4"
              secondary="Verificar los datos personales y el resultado del test"
            />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText primary="Paso 4 de 4" secondary="Enviar datos" />
          </ListItem>
        </List>
      </nav>
      <Typography variant="h3">Términos y Condiciones</Typography>
      <Typography variant="body1">
        Estos Términos y Condiciones regulan la descarga, acceso y utilización
        de la aplicación móvil CAÑIA (en adelante, la “APLICACIÓN”), que el
        SERVICIO CÁNTABRO DE SALUD (en adelante, "SCSalud") pone a disposición
        de los usuarios autorizados para su uso.
        <br />
        Esta versión de la APLICACIÓN está disponible de forma gratuita.
        <br />
        El acceso a la APLICACIÓN supone que el usuario reconoce ha aceptado y
        consentido sin reservas de las presentes condiciones de uso.
      </Typography>
      <Typography variant="h4">1. Objeto</Typography>
      <Typography variant="body1">
        La APLICACIÓN tiene el objetivo de la lectura automatizada de resultados
        de pruebas COVID19.
        <br />
        Algunos de los colectivos que pueden beneficiarse de este proyecto son
        centros sociosanitarios y educativos. En el diseño y desarrollo de esta
        APLICACIÓN han intervenido profesionales especialistas así como un grupo
        de usuarios que participaron en el periodo de prueba.
        <br />
        Funciona en un teléfono móvil Android o iOS con CÁMARA frontal y
        trasera.
      </Typography>
      <Typography variant="h4">
        2. DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL
      </Typography>
      <Typography variant="body1">
        Los derechos de propiedad intelectual e industrial sobre la APLICACIÓN
        son titularidad de SCSalud, correspondiéndole el ejercicio exclusivo de
        los derechos de explotación de los mismos en cualquier forma y, en
        especial, los derechos de reproducción, distribución, comunicación
        pública y transformación.
        <br />
        Los terceros titulares de derechos de propiedad intelectual e industrial
        sobre fotografías, logotipos, y cualesquiera otros símbolos o contenidos
        incluidos en la APLICACIÓN han concedido las correspondientes
        autorizaciones para su reproducción, distribución y puesta a disposición
        del público.
        <br />
        El usuario reconoce que la reproducción, modificación, distribución,
        comercialización, descompilación, desensamblado, utilización de técnicas
        de ingeniería inversa o de cualquier otro medio para obtener el código
        fuente, transformación o publicación de cualquier resultado de pruebas
        de referencias no au torizadas de cualquiera de los elementos y
        utilidades integradas dentro del desarrollo constituye una infracción de
        los derechos de propiedad intelectual de SCSalud, obligándose, en
        consecuencia, a no realizar ninguna de las acciones mencionadas.
      </Typography>
      <Typography variant="h4">3. POLITICA DE PRIVACIDAD</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}>HISTORIA CLINICA</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Fines del tratamiento
              </TableCell>
              <TableCell>
                Gestión y control de los datos de la historia clínica del
                paciente para el seguimiento del mismo, gestión de la actividad
                asistencial, estudios de morbilidad, gestión de incapacidad
                temporal, actividad docente, gestión financiera y prestación
                sanitaria, producción de estadísticas e investigación sanitaria.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Responsable de tratamiento
              </TableCell>
              <TableCell>
                <b>Gerencia del Servicio Cántabro de Salud</b>
                <br />
                Dirección: Avd. Cardenal Herrera Oria s/n, 39011 Santander,
                Cantabria
                <br />
                <a href="tel:942 202 770">942 202 770</a>
                <br />
                <a href="mailto:buzgen.dg@scsalud.es">buzgen.dg@scsalud.es</a>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Delegado de Protección de Datos
              </TableCell>
              <TableCell>
                <a href="mailto:dpd@scsalud.es">dpd@scsalud.es</a>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Base jurídica del tratamiento
              </TableCell>
              <TableCell>
                RGPD: 6.1 c) el tratamiento es necesario para el cumplimiento de
                una obligación legal aplicable al responsable del tratamiento.
                <br />
                RGPD: 6.1 d) el tratamiento es necesario para proteger intereses
                vitales del interesado o de otra persona física.
                <br />
                RGPD: 6.1. e) el tratamiento es necesario para el cumplimiento
                de una misión realizada en interés público o en el ejercicio de
                poderes públicos conferidos al responsable del tratamiento;
                <br />
                RGPD: 9.2 g) el tratamiento es necesario por razones de un
                interés público esencial.
                <br />
                RGPD: 9.2 i) el tratamiento es necesario por razones de interés
                público en el ámbito de la salud pública.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Categorías de interesados
              </TableCell>
              <TableCell>
                Toda la población de la Comunidad Autónoma de Cantabria. También
                personas desplazadas de otras Comunidades o Países que requieran
                realizar algún trámite administrativo. Personas que acceden al
                sistema sanitario.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Categorías de datos
              </TableCell>
              <TableCell>
                Datos especialmente protegidos: Datos para la elaboración de la
                Historia Clínica. Datos identificativos: D.N.I./N.I.F., Nombre,
                Apellidos, Teléfono, Dirección, NºSS/Mutualidad, Tarjeta
                Sanitaria, Nº de Historia Clínica, Imagen/Voz. Otros Datos;
                Características Personales.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Categorías de destinatarios
              </TableCell>
              <TableCell>
                Organismos sociosanitarios y educativos de la CCAA de Cantabria.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Transferencias a terceros países
              </TableCell>
              <TableCell>
                No se tiene prevista la transferencia internacional de datos de
                carácter personal.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Plazos previstos para la supresión de los datos
              </TableCell>
              <TableCell>
                Los datos se conservarán durante el tiempo que sea necesario
                para la consecución de las finalidades antes mencionadas, para
                determinar las posibles responsabilidades que pudieran derivarse
                de éstas y del tratamiento de los datos y, en todo caso, durante
                los plazos establecidos por la legislación vigente.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Medidas técnicas y organizativas de seguridad
              </TableCell>
              <TableCell>
                Las exigidas por el Esquema de Seguridad Nacional (ENS) además
                de medidas específicas para garantizar la seguridad de la
                información no automatizada.
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h4">4. EXCLUSIÓN DE RESPONSABILIDAD</Typography>
      <Typography variant="body1">
        SCSalud se reserva el derecho de editar, actualizar, modificar,
        suspender, eliminar o finalizar los servicios ofrecidos por la
        Aplicación, incluyendo todo o parte de su contenido, sin necesidad de
        previo aviso, así como de modificar la forma o tipo de acceso a esta.
        <br />
        Las posibles causas de modificación pueden tener lugar, por motivos
        tales, como su adaptación a las posibles novedades legislativas y
        cambios en la propia Aplicación, así como a las que se puedan derivar de
        los códigos tipos existentes en la materia o por motivos estratégicos o
        corporativos.
        <br />
        SCSalud. no será responsable del uso de la APLICACIÓN, siendo la
        descarga y uso de la APLICACIÓN de la exclusiva responsabilidad del
        usuario.
        <br />
        La APLICACIÓN se presta “tal y como es” y sin ninguna clase de garantía.
        SCSalud no se hace responsable de la calidad final de la APLICACIÓN ni
        de que ésta sirva y cumpla con todos los objetivos de la misma. No
        obstante lo anterior, SCSalud se compromete en la medida de sus
        posibilidades a contribuir a mejorar la calidad de la APLICACIÓN, pero
        no puede garantizar la precisión ni la actualidad del contenido de la
        misma.
        <br />
        La responsabilidad de uso de la APLICACIÓN corresponde solo al usuario.
        Salvo lo establecido en estos Términos y Condiciones, SCSalud, S.L. no
        es responsable de ninguna pérdida o daño que se produzca en relación con
        la descarga o el uso de la APLICACIÓN, tales como los producidos como
        consecuencia de fallos, averías o bloqueos en el funcionamiento de la
        APLICACIÓN (por ejemplo, y sin carácter limitativo: error en las líneas
        de comunicaciones, defectos en el hardware o software de la APLICACIÓN o
        fallos en la red de Internet). Igualmente, SCSalud tampoco será
        responsable de los daños producidos como consecuencia de un uso indebido
        o inadecuado de la APLICACIÓN por parte de los usuarios.
      </Typography>
      <Typography variant="h4">5. LEGISLACIÓN Y FUERO</Typography>
      <Typography variant="body1">
        El usuario acepta que la legislación aplicable y los Juzgados y
        Tribunales competentes para conocer de las divergencias derivadas de la
        interpretación o aplicación de este clausulado son los españoles, y se
        somete, con renuncia expresa a cualquier otro fuero, a los juzgados y
        tribunales de la Comunidad Autónoma de Cantabria.
      </Typography>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 20px;
`;

export default PrivacyDataUse;
