import React from "react";
import Core, { GetComponent } from "@capdilla/react-d-form";
import Box from "@mui/material/Box";
import Input from "./Input";
import Select from "./Select";
import DatePicker from "./DatePicker";
import PhonePicker from "./PhonePicker";
import Autocomplete from "./Autocomplete";

const FormComponents = {
  Input,
  Select,
  DatePicker,
  PhonePicker,
  Autocomplete,
};

const Element = (props: React.ReactPropTypes) =>
  GetComponent(FormComponents, props);

export default class Form<T> extends Core<T> {
  render(): React.ReactElement {
    return (
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { marginBottom: 3, width: "100%" },
        }}
        noValidate
        autoComplete="off"
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          const inputTags = document.getElementsByTagName("input");
          inputTags[inputTags.length - 1].blur();
          event.preventDefault();
        }}
      >
        {this.rows((rows: any) => (
          <div key={rows.rowKey}>
            {this.fieldFn(rows, (r: any, i: number) => (
              <Element key={`form_element_${i}`} {...r} />
            ))}
          </div>
        ))}
      </Box>
    );
  }
}
