import { GraphQLResult } from "@aws-amplify/api-graphql";
import Observable from "zen-observable-ts";

export function isGraphQLResult<T extends object>(
  result: GraphQLResult<object> | Observable<object>,
): result is GraphQLResult<T> {
  return (
    (result as GraphQLResult<T>).data !== undefined ||
    (result as GraphQLResult<T>).errors !== undefined
  );
}

export function isGraphQLSubscription<T extends object>(
  result: GraphQLResult<object> | Observable<object>,
): result is Observable<T> {
  return (result as Observable<T>).subscribe !== undefined;
}

export function isGraphQLError<T extends object>(
  result: any,
): result is GraphQLResult<object> {
  return (
    (result as GraphQLResult<T>).data === null &&
    (result as GraphQLResult<T>).errors !== undefined
  );
}

export function isCustomError(error: any): error is { message: string } {
  return error.message !== undefined;
}
