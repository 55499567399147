import React, { useContext } from "react";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import { CaptureTestContext } from "./context";
import { CaptureTestScreenTypes } from "./util";
import TestHeader from "../../components/TestHeader";

const Header = React.memo(() => {
  const { setActiveScreen, activeScreen } = useContext(CaptureTestContext);
  const [t] = useTranslation();

  const toggleActiveScreen = () => {
    if (activeScreen === CaptureTestScreenTypes.OCR) {
      setActiveScreen(CaptureTestScreenTypes.PASSPORT);
    } else {
      setActiveScreen(CaptureTestScreenTypes.OCR);
    }
  };

  const subtitleLink =
    activeScreen === CaptureTestScreenTypes.OCR
      ? t("captureTest.captureTestWithPassport")
      : t("captureTest.captureTestWithOCR");

  const subtitle =
    activeScreen === CaptureTestScreenTypes.OCR
      ? t("captureTest.captureTestSubtitleOCR")
      : {
          message: t("captureTest.captureTestSubtitlePassport.partOne"),
          bold: t("captureTest.captureTestSubtitlePassport.partTwo"),
        };

  return (
    <TestHeader title={t("captureTest.captureTestTitle")} subtitle={subtitle}>
      <Typography
        variant="subtitle2"
        style={{ textDecorationLine: "underline" }}
        onClick={toggleActiveScreen}
        data-cy="toggle-mode"
      >
        {subtitleLink}
      </Typography>
    </TestHeader>
  );
});

export default Header;
