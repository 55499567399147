import React, { FC } from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";

interface TestHeaderProps {
  title: string;
  subtitle: string | { message: string; bold: string };
}

const TestHeader: FC<TestHeaderProps> = React.memo(
  ({ title, subtitle, children }) => {
    return (
      <Container>
        <Typography variant="h4">{title}</Typography>
        {typeof subtitle !== "string" ? (
          <Typography variant="subtitle1">
            {subtitle.message}
            <b>{subtitle.bold}</b>
          </Typography>
        ) : (
          <Typography variant="subtitle1">{subtitle}</Typography>
        )}
        {children}
      </Container>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export default TestHeader;
