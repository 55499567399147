import Home from "../screens/Home";
import CaptureTest from "../screens/CaptureTest";
import Confirm from "../screens/Confirm";
import Final from "../screens/Final";
import NotFound from "../screens/404";
import DataPrivacy from "../screens/DataPrivacy";
import ExtraInfo from "../screens/ExtraInfo";

export interface Route {
  path: string;
  exact?: boolean;
  component: React.ReactElement;
}

const routes: Route[] = [
  {
    path: "/",
    exact: true,
    component: <Home />,
  },
  {
    path: "/capture",
    exact: true,
    component: <CaptureTest />,
  },
  {
    path: "/confirm",
    exact: true,
    component: <Confirm />,
  },
  {
    path: "/extraInfo",
    exact: true,
    component: <ExtraInfo />,
  },
  {
    path: "/final",
    exact: true,
    component: <Final />,
  },
  {
    path: "/privacy",
    exact: true,
    component: <DataPrivacy />,
  },
  {
    path: "*",
    exact: undefined,
    component: <NotFound />,
  },
];

export default routes;
