import { TextField } from "@mui/material";
import { withError } from "@capdilla/react-d-form";

const Input = withError(
  ({ label, onChange, props, value, placeholder, error }) => {
    return (
      <TextField
        variant="outlined"
        size="small"
        fullWidth
        error={!!error}
        label={label}
        placeholder={placeholder}
        onChange={(event) => onChange(event.target.value)}
        helperText={error?.content}
        value={value}
        {...props}
      />
    );
  },
);

export default Input;
