import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";
import { FC } from "react";

interface ErrorAlertProps {
  error: Error | undefined;
  setError: React.Dispatch<React.SetStateAction<Error | undefined>>;
}

const ErrorAlert: FC<ErrorAlertProps> = ({ error, setError }) => {
  const [t] = useTranslation();

  return (
    <Collapse in={!!error}>
      <Alert
        action={
          <IconButton
            data-testid="close-error-alert"
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setError(undefined);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        severity="error"
        style={{ marginBottom: 20 }}
      >
        {error && t(error.message)}
      </Alert>
    </Collapse>
  );
};

export default ErrorAlert;
