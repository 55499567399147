export const isDniValid = (
  nif: string,
  type: "DNI" | "NIE" = "DNI",
): boolean => {
  let nifVerified = nif;

  if (!nifVerified) {
    return false;
  }

  // eslint-disable-next-line no-useless-escape
  nifVerified = nifVerified.toUpperCase().replace(/[\s\-]+/g, "");
  if (/^(\d|[XYZ])\d{7}[A-Z]$/.test(nifVerified)) {
    const num = nifVerified.match(/\d+/);
    const number: number = parseInt(
      // eslint-disable-next-line no-nested-ternary
      (nifVerified[0] !== "Z" ? (nifVerified[0] !== "Y" ? 0 : 1) : 2) +
        (num || "").toString(),
      10,
    );

    if (nifVerified[8] === "TRWAGMYFPDXBNJZSQVHLCKE"[number % 23]) {
      const checkDni = /^\d/.test(nifVerified);

      if (checkDni && type === "DNI") {
        return true;
      }

      if (!checkDni && type === "NIE") {
        return true;
      }
    }
  }

  return false;
};
