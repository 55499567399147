import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import {
  ConfirmWebTestReportInput,
  ConfirmWebTestReportMutation,
  ConfirmWebTestReportMutationVariables,
  TestReportConfirmedResult
} from "../../api";
import Button from "../../components/Button";
import ReplayIcon from "@mui/icons-material/Replay";
import SendIcon from "@mui/icons-material/Send";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import TestHeader from "../../components/TestHeader";
import Form from "../../components/Form";
import { confirmWebTestReport } from "../../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";
import { isGraphQLResult } from "../../utils/graphql";

const ExtraInfo = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const location = useLocation();
  const { state } = location;

  useEffect(() => {
    if (state === null) {
      navigate("/capture");
    }
  }, [state, navigate]);

  const vulnerableInitialState = {
    IMD: false,
    EMB: false,
    NVC: false,
    PSS: false,
    CPV: false,
  };
  const [showValidation, setShowValidation] = useState(false);
  const [symptoms, setSymptoms] = useState(false);
  const [symptomsDate, setSymptomsDate] = useState(null);
  const [contact] = useState(false);
  const [vulnerable, setVulnerable] = useState(vulnerableInitialState);

  if (!state) return null;

  /**
   * Send data and result to confirm test result
   */
  const onSendButton = async () => {
    if (!state.testCreated) {
      return;
    }

    if ((symptomsDate === null || symptomsDate === "") && symptoms) {
      setShowValidation(true);
      return;
    }

    const payload: ConfirmWebTestReportInput = {
      uuid: state.testCreated.uuid,
      results: state.testCreated.results,
      data: {
        docTypeId: state.testCreated.docType.type,
        idValue: state.testCreated.idValue,
        fullname: state.testCreated.fullname,
        phoneNumber: state.testCreated.phoneNumber,
        birthday: state.testCreated.birthday,
        nationality: state.testCreated.nacionality,
      },
      additionalData: {
        symptoms:
          symptomsDate === "" ? null : moment.utc(symptomsDate).toISOString(),
        contact: contact,
        vulnerable: vulnerable,
      },
    };

    const testReport = await confirmTestReport(payload);

    if (testReport) {
      navigate("/final", {
        state: {
          testCreated: testReport,
        },
      });
    }
  };

  /**
   * Confirms the test report calling the AppSync mutation.
   * @param input Input to confirm the test report with a mutation
   * @returns The test report confirmed
   */
  const confirmTestReport = async (input: ConfirmWebTestReportInput) => {
    let arrayResutls: Array<TestReportConfirmedResult> = [];
    input.results.forEach((item: any) => {
      arrayResutls.push({ virusId: item.virusId, result: item.result } as TestReportConfirmedResult);
    });
    input.results = arrayResutls;
    const inputToSend = input as ConfirmWebTestReportInput;
    const variables: ConfirmWebTestReportMutationVariables = {
      input: inputToSend,
    };
    const response = await API.graphql(
      graphqlOperation(confirmWebTestReport, variables),
    );
    if (isGraphQLResult<ConfirmWebTestReportMutation>(response)) {
      
      if (response.data) {
        return response.data.confirmWebTestReport;
      } else {
        throw new Error("Response had no data");
      }
    } else {
      throw new Error("The response was not of the expected object");
    }
  };

  return (
    <Container>
      <TestHeader
        title={t("extraInfo.title")}
        subtitle={t("extraInfo.subtitle")}
      />
      <Grid container direction="column" alignItems="center">
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        ></Grid>
        <Grid item container alignItems="top">
          <TextContainer>
            <FormContainer>
              <Typography variant="h5">
                {t("extraInfo.form.symptoms")}
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="symptoms"
                  name="row-radio-buttons-group"
                  defaultValue={false}
                >
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    onChange={() => {
                      setSymptoms(true);
                    }}
                    label={t("extraInfo.form.yes").toString()}
                  />
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    onChange={(state) => {
                      setSymptoms(false);
                      setSymptomsDate(null);
                    }}
                    label={t("extraInfo.form.no").toString()}
                  />
                </RadioGroup>
              </FormControl>
              <div style={{ paddingTop: "10px" }}>
                <Form
                  fields={[
                    {
                      fields: [
                        {
                          name: "symptomsDate",
                          type: "DatePicker",
                          label: t("extraInfo.form.initialSymptomDate"),
                          validation: {
                            required: true,
                            errorMessage: t(
                              "captureTest.errors.validation.birthday",
                            ),
                          },
                          props: {
                            maxDate: moment(),
                            disabled: !symptoms,
                          },
                        },
                      ],
                    },
                  ]}
                  executeChangeOnBlur={false}
                  showValidation={showValidation}
                  onFormChange={(state) => {
                    setSymptomsDate(state.data.symptomsDate);
                  }}
                />
              </div>
              {/*
              ESTE CAMPO SE QUITA DEL FRONT HASTA NUEVO AVISO.
              EL BACK LO SEGUIRA INTERPRETANDO COMO false SIEMPRE.

              <Typography style={{ paddingTop: '10px' }} variant="h5">{t("extraInfo.form.contact")}</Typography>
              <FormControl component="fieldset">
                <RadioGroup row aria-label="contact" name="row-radio-buttons-group" defaultValue={false}>
                  <FormControlLabel value={true} control={<Radio />} onChange={() => {
                    setContact(true)
                  }} label={t("extraInfo.form.yes").toString()} />
                  <FormControlLabel value={false} control={<Radio defaultChecked />} onChange={() => {
                    setContact(false)
                  }} label={t("extraInfo.form.no").toString()} />
                </RadioGroup>
              </FormControl>
              */}
              <Typography style={{ paddingTop: "20px" }} variant="h5">
                {t("extraInfo.form.vulnerableGroup")}
              </Typography>
              <Typography sx={{ color: "gray" }} variant="body1">
                {t("extraInfo.form.markThoseWhichApply")}
              </Typography>
              <FormGroup>
                <FormControlLabel
                  onChange={() => {
                    const tempVulnerable = { ...vulnerable };
                    tempVulnerable.IMD = !tempVulnerable.IMD;
                    setVulnerable(tempVulnerable);
                  }}
                  control={<Checkbox checked={vulnerable.IMD} />}
                  label={t("extraInfo.vulnerabilityGroups.IMD").toString()}
                />
                <FormControlLabel
                  onChange={() => {
                    const tempVulnerable = { ...vulnerable };
                    tempVulnerable.EMB = !tempVulnerable.EMB;
                    setVulnerable(tempVulnerable);
                  }}
                  control={<Checkbox checked={vulnerable.EMB} />}
                  label={t("extraInfo.vulnerabilityGroups.EMB").toString()}
                />
                <FormControlLabel
                  onChange={() => {
                    const tempVulnerable = { ...vulnerable };
                    tempVulnerable.NVC = !tempVulnerable.NVC;
                    setVulnerable(tempVulnerable);
                  }}
                  control={<Checkbox checked={vulnerable.NVC} />}
                  label={t("extraInfo.vulnerabilityGroups.NVC").toString()}
                />
                <FormControlLabel
                  onChange={() => {
                    const tempVulnerable = { ...vulnerable };
                    tempVulnerable.PSS = !tempVulnerable.PSS;
                    setVulnerable(tempVulnerable);
                  }}
                  control={<Checkbox checked={vulnerable.PSS} />}
                  label={t("extraInfo.vulnerabilityGroups.PSS").toString()}
                />
                <FormControlLabel
                  onChange={() => {
                    const tempVulnerable = { ...vulnerable };
                    tempVulnerable.CPV = !tempVulnerable.CPV;
                    setVulnerable(tempVulnerable);
                  }}
                  control={<Checkbox checked={vulnerable.CPV} />}
                  label={t("extraInfo.vulnerabilityGroups.CPV").toString()}
                />
                <FormControlLabel
                  onChange={() => {
                    const tempVulnerable = { ...vulnerable };
                    tempVulnerable.IMD = false;
                    tempVulnerable.EMB = false;
                    tempVulnerable.NVC = false;
                    tempVulnerable.PSS = false;
                    tempVulnerable.CPV = false;
                    setVulnerable(tempVulnerable);
                  }}
                  control={
                    <Checkbox
                      defaultChecked
                      checked={
                        !Object.values(vulnerable).some((e) => e === true)
                      }
                    />
                  }
                  label={t("extraInfo.vulnerabilityGroups.NONE").toString()}
                />
              </FormGroup>
            </FormContainer>
          </TextContainer>
        </Grid>
      </Grid>
      <ButtonsContainer>
        <RetryButton
          onClick={async () => {
            navigate("/confirm", {
              state: {
                image: state.image,
                testCreated: state.testCreated,
              },
            });
          }}
          endIcon={<ReplayIcon color={"primary"} />}
          data-cy="back-btn"
        >
          {t("confirmTest.goBack")}
        </RetryButton>
        <Button
          onClick={onSendButton}
          endIcon={<SendIcon htmlColor={"white"} />}
          loadingPosition="end"
          data-cy="send-btn"
        >
          {t("extraInfo.send")}
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled("div")(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.up("md")]: {
    backgroundColor: "white",
  },
  [theme.breakpoints.down("md")]: {
    backgroundColor: "white",
  },
}));

const FormContainer = styled("div")(() => ({
  width: "100%",
  marginTop: "10px",
  marginBottom: "30px",
  justifyContent: "space-between",
}));

const TextContainer = styled("div")(() => ({
  width: "100%",
  marginTop: "10px",
  marginBottom: "20px",
  justifyContent: "space-between",
}));

const ButtonsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 14px;
  padding-bottom: 10%;
`;

const RetryButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  border: `1.75px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
}));

export default ExtraInfo;
