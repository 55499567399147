const isNameValid = (name: string): boolean => {
  if (!name || !name.trim()) {
    return false;
  }

  if (
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+[^\s]$/u.test(
      name,
    )
  ) {
    return true;
  }

  return false;
};

export default isNameValid;
