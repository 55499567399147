import { FormData } from "@capdilla/react-d-form";
import ReplayIcon from "@mui/icons-material/Replay";
import SendIcon from "@mui/icons-material/Send";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { DocTypeType, TestReport, TestResult } from "../../api";
import Button from "../../components/Button";
import Form from "../../components/Form";
import TestHeader from "../../components/TestHeader";
import nationalities from "../../utils/nationalities.json";
// import theme from "../../utils/theme";
import { isDocumentValid } from "../../utils/validators/idDocumentValid";
import isNameValid from "../../utils/validators/isNameValid";
import { phoneNumberField } from "../CaptureTest/util";
import { resultsTypeSelectField, ConfirmTest } from "./util";


interface ILocation {
  state: {
    image: string;
    testCreated: TestReport;
  } | null;
}

const Confirm = () => {
  const location: ILocation = useLocation();

  const { state } = location;

  const initialConfirmedTest = {
    uuid: state?.testCreated.uuid || "",
    fullName: state?.testCreated.fullname || "",
    docTypeId: state?.testCreated.docType.type || DocTypeType.DNI,
    idValue: state?.testCreated.idValue || "",
    results: state?.testCreated.results || [],
    COVID_19: state?.testCreated.results ? state?.testCreated.results.find(({virus}) => virus?.name === "COVID_19")?.result || TestResult.UNDEFINED : TestResult.UNDEFINED,
    FLU_A: state?.testCreated.results ? state?.testCreated.results.find(({virus}) => virus?.name === "FLU_A")?.result || TestResult.UNDEFINED : TestResult.UNDEFINED,
    FLU_B: state?.testCreated.results ? state?.testCreated.results.find(({virus}) => virus?.name === "FLU_B")?.result || TestResult.UNDEFINED : TestResult.UNDEFINED,
    phoneNumber: state?.testCreated.phoneNumber || "",
    birthday: state?.testCreated.birthday || "",
    nationality: state?.testCreated.nacionality || "",
  };
  const initialIsFormValid = state?.testCreated.results?.every(({result}) => result !== TestResult.UNDEFINED);
  const confirmTestForm: FormData<ConfirmTest> = {
    validation: { isFormValid: initialIsFormValid ? initialIsFormValid : false },
    data: initialConfirmedTest,
  };

  const [showValidation, setShowValidation] = useState(false);
  const [confirmedTest, setConfirmedTest] =
    useState<FormData<ConfirmTest>>(confirmTestForm);
  const [confirmedTest2] =
    useState<ConfirmTest>(initialConfirmedTest);
  const [t] = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!location || !location.state) {
      navigate("/capture");
    }
  }, [location, navigate]);

  // const getColorFromTestResult = (result: TestResult) => {
  //   switch (result) {
  //     case TestResult.NEGATIVE:
  //       return theme.palette.success.main;
  //     case TestResult.POSITIVE:
  //       return theme.palette.error.main;
  //     default:
  //       return "";
  //   }
  // };

  // const getColorFromTestResults = (results: Array<any>) => {
  //   const testResultNegative = results.every(result => result === TestResult.NEGATIVE);
  //   const testResultPositive = results.every(result => result === TestResult.POSITIVE);

  //   if(testResultNegative){
  //     return theme.palette.success.main;
  //   } else if(testResultPositive){
  //     return theme.palette.error.main;
  //   } else {
  //     return "";
  //   }
  // };

  /**
   * Send data and result to confirm test result
   */
  const onSendButton = async () => {
    if (!confirmedTest) {
      setShowValidation(true);
      return;
    }

    const { data, validation } = confirmedTest;

    if (!validation.isFormValid) {
      setShowValidation(true);
      return;
    }

    if (!state) {
      setShowValidation(true);
      return;
    }
    confirmedTest2.results.forEach(itemResult => {
      if(Object.keys(confirmedTest.data).find(item => item === itemResult.virus?.name)){
        if(Object.keys(confirmedTest.data).find(item => item === itemResult.virus?.name) === "COVID_19"){
          itemResult.result =  confirmedTest.data.COVID_19;
        } else if (Object.keys(confirmedTest.data).find(item => item === itemResult.virus?.name) === "FLU_A") {
          itemResult.result =  confirmedTest.data.FLU_A;
        } else if (Object.keys(confirmedTest.data).find(item => item === itemResult.virus?.name) === "FLU_B"){
          itemResult.result =  confirmedTest.data.FLU_B;
        }
      }
    });

    confirmedTest.data.results = confirmedTest2.results;
    data.results = confirmedTest2.results;
    state.testCreated.results = data.results;
    state.testCreated.idValue = data.idValue;
    state.testCreated.fullname = data.fullName;
    state.testCreated.phoneNumber = data.phoneNumber;
    state.testCreated.nacionality = data.nationality;
    state.testCreated.birthday = moment.utc(data.birthday).toISOString();

    if (confirmedTest) {
      navigate("/extraInfo", { state });
    }
  };

  return (
    <Container>
      <TestHeader
        title={t("confirmTest.confirmTestTitle")}
        subtitle={t("confirmTest.confirmTestSubtitle")}
      />
      <div style={{ margin: "20px 0px" }} />
      <Form
        fields={[
          {
            fields: [
              {
                name: "fullName",
                type: "Input",
                label: t("captureTest.fullName"),
                validation: {
                  required: true,
                  custom: ({ fullName }) => ({
                    valid: fullName ? isNameValid(fullName) : false,
                    errorMessage: t("captureTest.errors.validation.fullName"),
                  }),
                },
                props: {
                  placeholder: t("captureTest.fullNamePlaceholder"),
                  "data-cy": "fullname-input",
                  inputProps: {
                    maxLength: 150,
                  },
                },
              },
            ],
          },
          {
            fields: [
              {
                name: "idValue",
                type: "Input",
                label: t(
                  `confirmTest.docType.${initialConfirmedTest.docTypeId}`,
                ),
                validation: {
                  required: true,
                  errorMessage: t("confirmTest.errors.validation.idValue"),
                  custom: (values) =>
                    isDocumentValid(
                      initialConfirmedTest.docTypeId,
                      values.idValue,
                    ),
                },
                props: {
                  "data-cy": "idValue-input",
                },
              },
            ],
          },
          {
            fields: [
              {
                name: "nationality",
                type: "Autocomplete",
                data: nationalities,
                label: t("captureTest.passportScreen.nationality"),
                validation: {
                  required: true,
                  errorMessage: t("captureTest.errors.validation.nationality"),
                },
              },
            ],
          },
          {
            fields: [
              {
                name: "birthday",
                type: "DatePicker",
                label: t("captureTest.passportScreen.birthday"),
                validation: {
                  required: true,
                  errorMessage: t("captureTest.errors.validation.birthday"),
                  custom: (values) => {
                    if (
                      values?.birthday &&
                      new Date() <= new Date(values?.birthday)
                    ) {
                      return {
                        valid: false,
                        errorMessage: t(
                          "captureTest.errors.validation.birthdayMustBeLower",
                        ),
                      };
                    }

                    return true;
                  },
                },
                props: {
                  maxDate: moment(),
                },
              },
            ],
          },
          phoneNumberField(t),
          resultsTypeSelectField(t, confirmedTest2.results)

        ]}
        defaultState={confirmedTest.data}
        onFormChange={setConfirmedTest}
        showValidation={showValidation}
        executeChangeOnBlur={false}
      />

      <ImageContainer docTypeId={initialConfirmedTest.docTypeId}>
        <Image src={state?.image} />
      </ImageContainer>

      <ButtonsContainer>
        <RetryButton
          onClick={async () => navigate("/capture")}
          endIcon={<ReplayIcon color={"primary"} />}
          data-cy="back-btn"
        >
          {t("confirmTest.goBack")}
        </RetryButton>
        <Button
          onClick={onSendButton}
          endIcon={<SendIcon htmlColor={"white"} />}
          loadingPosition="end"
          data-cy="send-btn"
        >
          {t("confirmTest.continue")}
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled("div")(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.up("md")]: {
    backgroundColor: "white",
  },
  [theme.breakpoints.down("md")]: {
    backgroundColor: "white",
  },
}));

const ImageContainer = styled("div")`
  height: ${(props: { docTypeId: DocTypeType }) =>
    props.docTypeId === DocTypeType.PASSPORT ? "102px" : "300px"};
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
`;

const Image = styled("img")`
  height: 300px;
  border-radius: 5px;
  width: 100%;
  object-fit: cover;
`;

const ButtonsContainer = styled("div")`
  display: flex;
  flex-direction: row;
  gap: 14px;
  padding-bottom: 10%;
`;

const RetryButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  border: `1.75px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
}));

export default Confirm;
