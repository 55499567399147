import API, { graphqlOperation } from "@aws-amplify/api";
import { FormData } from "@capdilla/react-d-form";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Webcam from "react-webcam";
import {
  VirusType,
  CreateTestReportInput,
  CreateTestReportMutation,
  CreateTestReportMutationVariables,
  CreateWebTestReportInput,
  CreateWebTestReportMutation,
  CreateWebTestReportMutationVariables,
  DocTypeType,
} from "../../api";
import Button from "../../components/Button";
import ErrorAlert from "../../components/ErrorAlert";
import { createTestReport, createWebTestReport } from "../../graphql/mutations";
import dictionary from "../../locales/lang/es";
import {
  isCustomError,
  isGraphQLError,
  isGraphQLResult,
} from "../../utils/graphql";
import Camera from "./Camera";
import CaptureWithOCR from "./CaptureWithOCR";
import CaptureWithPassport from "./CaptureWithPassport";
import { CaptureTestContext } from "./context";
import Footer from "./Footer";
import Header from "./Header";
import { CaptureTestScreenTypes, UserData } from "./util";

type DictionaryCaptureTestErrors = keyof typeof dictionary.captureTest.errors;
type TestReportCreated = CreateTestReportMutation["createTestReport"];

const CaptureTest = () => {
  const [userData, setUserData] = useState<FormData<UserData>>();
  const [showValidation, setShowValidation] = useState(false);
  const [activeScreen, setActiveScreen] = useState<CaptureTestScreenTypes>(
    CaptureTestScreenTypes.OCR,
  );
  const [error, setError] = useState<Error>();
  const [image, setImage] = useState<string>();
  const [isFileUploaded, setIsFileUploaded] = useState<boolean>(false);
  const camera = useRef<Webcam>(null);
  const [t] = useTranslation();
  const navigate = useNavigate();

  /**
   * Shows a toast with an error message
   * @param error Error that was thrown. May be from graphql request or custom.
   * @param key Key of the captureTest.errors list of errors
   * @returns void
   */
  const handleError = (error: unknown, key: DictionaryCaptureTestErrors) => {
    const dictionaryKeys = Object.keys(dictionary.captureTest.errors);

    // Reset the camera
    setImage(undefined);

    if (isGraphQLError(error) && error.errors) {
      const message: string = error.errors[0].message;

      if (dictionaryKeys.includes(key)) {
        const dictionaryErrorKeys = Object.keys(
          dictionary.captureTest.errors[key],
        );

        if (dictionaryErrorKeys.includes(message)) {
          setError(new Error(`captureTest.errors.${key}.${message}`));
          return;
        }
      }
    }

    if (typeof error === "object" && error && isCustomError(error)) {
      if (dictionaryKeys.includes(key)) {
        const dictionaryErrorKeys = Object.keys(
          dictionary.captureTest.errors[key],
        );

        if (dictionaryErrorKeys.includes(error.message)) {
          setError(new Error(`captureTest.errors.${key}.${error.message}`));
          return;
        }
      }
    }

    setError(new Error("captureTest.errors.generic"));
  };

  /**
   * Takes a picture using the camera
   * @returns Image on base64 string
   */
  const takePicture = useCallback(() => {
    // Capture picture from camera
    if (camera && camera.current) {
      let imageSource: string | null;
      if (process.env.REACT_APP_ENV === "test" || isFileUploaded) {
        imageSource = camera.current.getScreenshot();
      } else {
        // Camera image canvas
        const imageCanvas = camera.current.getCanvas();
        if (!imageCanvas) return;
        const imageCtx = imageCanvas.getContext("2d");
        if (!imageCtx) return;

        // New canvas
        const canvas = document.createElement("canvas");
        canvas.width = imageCanvas.width;
        canvas.height = imageCanvas.width;
        const ctx = canvas.getContext("2d");
        if (!ctx) return;
        let y = Math.round(imageCanvas.height / 2 - imageCanvas.width / 2);
        ctx.putImageData(
          imageCtx.getImageData(0, y, imageCanvas.width, imageCanvas.width),
          0,
          0,
        );

        imageSource = canvas.toDataURL("image/png");
      }

      if (!imageSource) {
        console.error("Picture could not be taken");
        throw new Error("captureTest.errors.image.noImage");
      }

      setImage(imageSource);
      return imageSource;
    }

    throw new Error("captureTest.errors.camera");
  }, [camera, isFileUploaded]);

  /**
   * Creates the test report calling the AppSync mutation.
   * @param input Input to create the test report with a mutation
   * @returns The test report created
   */
  const sendTest = async (
    input: CreateTestReportInput | CreateWebTestReportInput,
  ) => {
    if (activeScreen === CaptureTestScreenTypes.PASSPORT) {
      const inputToSend = input as CreateTestReportInput;
      const variables: CreateTestReportMutationVariables = {
        input: inputToSend,
      };
      const response = await API.graphql(
        graphqlOperation(createTestReport, variables),
      );
      if (isGraphQLResult<CreateTestReportMutation>(response)) {
        if (response.data) {
          return response.data.createTestReport;
        } else {
          throw new Error("Response had no data");
        }
      } else {
        throw new Error("The response was not of the expected object");
      }
    } else {
      // activeScreen is OCR
      const inputToSend = input as CreateWebTestReportInput;
      const variables: CreateWebTestReportMutationVariables = {
        input: inputToSend,
      };
      const response = await API.graphql(
        graphqlOperation(createWebTestReport, variables),
      );
      if (isGraphQLResult<CreateWebTestReportMutation>(response)) {
        if (response.data) {
          return response.data.createWebTestReport;
        } else {
          throw new Error("Response had no data");
        }
      } else {
        throw new Error("The response was not of the expected object");
      }
    }
  };

  /**
   * Handler when the take picture button is pressed.
   * @returns void
   */
  const onCaptureTest = async () => {
    if (!userData) {
      setShowValidation(true);
      return;
    }

    const {
      data: { fullName, idValue, phoneNumber, birthday, nationality, virusType },
      validation: { isFormValid },
    } = userData;
    const isPassportScreen = activeScreen === CaptureTestScreenTypes.PASSPORT;

    // Second validation after deconstructing
    if (
      !isFormValid ||
      !phoneNumber ||
      !virusType ||
      (isPassportScreen && (!fullName || !idValue))
    ) {
      setShowValidation(true);
      return;
    }

    let image: string = "";
    try {
      // @ts-ignore
      image = takePicture();
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
      }
    }

    if (!image) {
      return handleError({ message: "noImage" }, "image");
    }

    let testReport: TestReportCreated;
    const isPassportFormValid = isPassportScreen && fullName && idValue;
    let temp_virusType = virusType === VirusType.COVID ? VirusType.COVID : VirusType.COVID_AND_FLU;
    if (isPassportFormValid) {
      const createTestReportInput: CreateTestReportInput = {
        fullname: fullName,
        idValue,
        phoneNumber,
        docTypeId: DocTypeType.PASSPORT,
        birthday: moment(birthday).format("YYYY-MM-DDTHH:mm:ss") + ".000Z",
        image,
        nacionality: nationality || "ESP",
        virusType: virusType === VirusType.COVID ? VirusType.COVID : VirusType.COVID_AND_FLU
      };

      try {
        console.log("Creating test report", {
          ...createTestReportInput,
          image: createTestReportInput.image.substr(0, 50),
        });

        testReport = await sendTest(createTestReportInput);
      } catch (error) {
        console.log(error);
        return handleError(error, "onCreateTestReport");
      }
    } else {
      
      // Is OCR screen
      const createTestReportInput: CreateWebTestReportInput = {
        phoneNumber: phoneNumber,
        image: image,
        virusType: temp_virusType
      };

      try {
        console.log("Creating test report", {
          ...createTestReportInput,
          image: createTestReportInput.image.substr(0, 50),
        });

        testReport = await sendTest(createTestReportInput);
      } catch (error) {
        console.log(error);
        return handleError(error, "onCreateTestReport");
      }
    }

    navigate("/confirm", {
      state: {
        image: image,
        testCreated: testReport,
      },
    });
  };

  const captureTestScreen: { [key in CaptureTestScreenTypes]: JSX.Element } = {
    [CaptureTestScreenTypes.OCR]: <CaptureWithOCR />,
    [CaptureTestScreenTypes.PASSPORT]: <CaptureWithPassport />,
  };

  return (
    <CaptureTestContext.Provider
      value={{
        camera,
        image,
        activeScreen,
        setActiveScreen,
        userData,
        setUserData,
        showValidation,
        isFileUploaded,
        setIsFileUploaded,
      }}
    >
      <Container>
        <Header />
        {captureTestScreen[activeScreen]}
        <Camera />
        <ErrorAlert error={error} setError={setError} />
        <Button onClick={onCaptureTest} data-cy="capture-btn">
          {t("captureTest.captureTestButton")}
        </Button>
        <Footer />
      </Container>
    </CaptureTestContext.Provider>
  );
};

const Container = styled("div")(({ theme }) => ({
  padding: theme.spacing(3),
  [theme.breakpoints.up("md")]: {
    backgroundColor: "white",
  },
  [theme.breakpoints.down("md")]: {
    backgroundColor: "white",
  },
}));

export default CaptureTest;
