import React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";

const Footer = React.memo(() => {
  const [t] = useTranslation();

  return (
    <React.Fragment>
      <Typography variant="body1" style={{ marginTop: 20 }}>
        {t("captureTest.photoInstructions.partOne")}
        <b>{t("captureTest.photoInstructions.partTwo")}</b>
        {t("captureTest.photoInstructions.partThree")}
        <b>{t("captureTest.photoInstructions.partFour")}</b>
        {t("captureTest.photoInstructions.partFive")}
      </Typography>
      <Typography variant="body1" style={{ marginTop: 5 }}>
        {t("captureTest.photoInstructions.validTestInfo")}
        <Link
          href="https://www.aemps.gob.es/informa/notasInformativas/productosSanitarios/2021/NI-PS_10-2021_pruebas-rapidas-antigeno.pdf?x69995"
          variant="body2"
          target="_blank"
          rel="noopener"
        >
          {t("captureTest.photoInstructions.validTestInfoHref")}
        </Link>
      </Typography>
    </React.Fragment>
  );
});

export default Footer;
