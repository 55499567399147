import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

import Logo from "../components/Logo";
import Button from "../components/Button";

const NotFound = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();

  return (
    <Container>
      <Logo />
      <Typography variant="h1">404</Typography>
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>
        <Typography
          variant="h4"
          style={{ textAlign: "center", textTransform: "none" }}
        >
          {t("404.notFound")}
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ textAlign: "center", marginTop: 3 }}
        >
          {t("404.notFoundDescription")}
        </Typography>
      </div>
      <ButtonContainer>
        <Button onClick={async () => navigate("/")}>{t("404.back")}</Button>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 22px;
  padding: 20px;
`;

const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding: 0px 60px;
`;

export default NotFound;
