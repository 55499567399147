import { Badge, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import theme from "../../utils/theme";
import {
  DocTypeType,
  TestReport,
  TestReportResult,
  TestResult,
} from "../../api";
import Button from "../../components/Button";
import Label from "../../components/Label";
import Logo from "../../components/Logo";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import nationalities from "../../utils/nationalities.json";

interface ConfirmTest {
  uuid: string;
  fullName: string;
  docTypeId: DocTypeType;
  idValue: string;
  results?: TestReportResult[] | [];
  createdAt: string;
  phoneNumber: string;
  nationality: string;
}

const Final = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const goBack = async () => {
    navigate("/capture");
  };

  const { state } = useLocation();

  useEffect(() => {
    if (state === null) {
      navigate("/capture");
    }
  }, [state, navigate]);

  if (!state) return null;

  const { testCreated }: { testCreated: TestReport } = state;

  const confirmTestForm: ConfirmTest = {
    uuid: testCreated?.uuid,
    fullName: testCreated?.fullname,
    docTypeId: testCreated?.docType.type,
    idValue: testCreated?.idValue,
    results: testCreated?.results || [],
    phoneNumber: testCreated?.phoneNumber || "",
    createdAt: testCreated?.createdAt || "",
    nationality: testCreated?.nacionality || "",
  };

  return (
    <Container>
      <Grid container direction="column" alignItems="center">
        <Logo />

        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <TextContainer>
            <TitleTypography
              variant="h4"
              style={{ paddingBottom: "25px" }}
              data-cy="title-text"
            >
              {t(`final.finalTitle`, {
                name: confirmTestForm?.fullName.split(" ")[0],
              }).toLowerCase()}
            </TitleTypography>
            <Typography variant="body1">{t("final.finalSubtitle")}</Typography>
          </TextContainer>
        </Grid>
        <Grid item container alignItems="top">
          <UserTestContainer>
            <Label label={t("final.reference")} value={confirmTestForm?.uuid} />

            <Label
              label={t("final.fullname")}
              value={confirmTestForm?.fullName}
              dataCy="fullname-text"
            />

            <Label
              label={t(`final.docType.${confirmTestForm?.docTypeId}`)}
              value={confirmTestForm?.idValue}
              dataCy="idValue-text"
            />

            <Label
              label={t(`final.nationality`)}
              value={
                nationalities.find(
                  (c) => c.code === confirmTestForm?.nationality,
                )?.label || ""
              }
              dataCy="nationality-text"
            />

            <Label
              label={t("final.mobilePhone")}
              value={`+${confirmTestForm?.phoneNumber}`}
              dataCy="phone-number-text"
            />

            <Label
              label={t("final.date")}
              value={moment(confirmTestForm?.createdAt).format(
                "DD/MM/YYYY, H:mm:ss",
              )}
            />

            {confirmTestForm?.results?.map((result, index) => {
              if (index === 0) {
                return (
                  <div>
                    <Label
                      label={t("final.result")}
                      key={result.testReportId}
                      value={
                        <div>
                          <Badge
                            sx={{
                              color: "black",
                              backgroundColor: "#FFFFFF",
                              padding: "2px 20px 2px 0px",
                              borderRadius: "5px",
                              borderWidth: "0px",
                              width: "100px",
                            }}
                          >
                            {t(`final.${result.virus?.name}`)}
                          </Badge>
                          <Badge
                            sx={{
                              color: "white",
                              backgroundColor:
                                result.result === TestResult.NEGATIVE
                                  ? theme.palette.success.main
                                  : theme.palette.error.main,
                              padding: "2px 5px",
                              borderRadius: "5px",
                              borderWidth: "0px",
                            }}
                          >
                            {/* Fix: Return results array each element  */}
                            {t(`final.resultValue.${result.result}`)}
                          </Badge>
                        </div>
                      }
                      dataCy="result-text"
                    />
                  </div>
                );
              } else {
                return (
                  <div className="css-vqwew8">
                    <Badge
                      sx={{
                        color: "black",
                        backgroundColor: "#FFFFFF",
                        padding: "2px 20px 2px 0px",
                        borderRadius: "5px",
                        borderWidth: "0px",
                        width: "100px",
                      }}
                    >
                      {t(`final.${result.virus?.name}`)}
                    </Badge>
                    <Badge
                      sx={{
                        color: "white",
                        backgroundColor:
                          result.result === TestResult.NEGATIVE
                            ? theme.palette.success.main
                            : theme.palette.error.main,
                        padding: "2px 5px",
                        borderRadius: "5px",
                        borderWidth: "0px",
                      }}
                    >
                      {/* Fix: Return results array each element  */}
                      {t(`final.resultValue.${result.result}`)}
                    </Badge>
                  </div>
                );
              }
            })}
          </UserTestContainer>
        </Grid>
        <Button onClick={goBack} data-cy="back-btn">
          {t("final.goBackButton")}
        </Button>
      </Grid>
    </Container>
  );
};

const Container = styled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "top",
  padding: 30,
  paddingTop: 25,
}));

const UserTestContainer = styled("div")(() => ({
  marginTop: "10px",
  marginBottom: "10px",
  justifyContent: "space-between",
}));

const TextContainer = styled("div")(() => ({
  marginTop: "25px",
  marginBottom: "20px",
  justifyContent: "space-between",
}));

const TitleTypography = styled(Typography)(() => ({
  textTransform: "capitalize",
}));

export default Final;
