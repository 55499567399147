import { Fields } from "@capdilla/react-d-form";
import { TFunction } from "i18next";
import isMobilePhone from "validator/lib/isMobilePhone";
import { DocTypeType } from "../../api";
// import Select from "../../components/Select";

export enum CaptureTestScreenTypes {
  OCR = "OCR",
  PASSPORT = "PASSPORT",
}

export interface UserData {
  fullName: string | undefined;
  idValue: string | undefined;
  phoneNumber: string | undefined;
  virusType: string | undefined;
  docType?: DocTypeType;
  birthday?: string | undefined;
  nationality?: string | undefined;
}

export enum virusTypeE {
  COVID = "COVID",
  COVID_AND_FLU = "COVID_AND_FLU"
}

const virusTypeList:Array<any> = [
  { label: "Test de COVID-19", key: "COVID"},
  { label: "Test de COVID-19, Gripe A y B", key: "COVID_AND_FLU"}
]

export const phoneNumberField = (
  t: TFunction,
): Fields<{ phoneNumber: string | undefined }> => ({
  fields: [
    {
      name: "phoneNumber",
      type: "PhonePicker",
      label: t("captureTest.phoneNumber"),
      validation: {
        required: true,
        custom: ({ phoneNumber }) => ({
          valid: phoneNumber ? isMobilePhone(phoneNumber) : false,
          errorMessage: t("captureTest.errors.validation.phoneNumber"),
        }),
      },
    },
  ],
});

export const virusTypeSelectField = (
  t: TFunction,
): Fields<{ virusType: string | undefined }> => ({
  fields: [
    {
      name: "virusType",
      type: "Select",
      data: virusTypeList.map(item => ({
        key: item.key,
        value: item.key,
        label: t(`captureTest.${item.key}`),
      })),
      label: t("captureTest.testTypes"),
      validation: {
        required: true,
        errorMessage: t("captureTest.errors.validation.testType"),
        custom: ({ virusType }) => {
          return (
            virusType === virusTypeE.COVID ||
            virusType === virusTypeE.COVID_AND_FLU
          );
        },
      },
      props: {        
        "data-cy": "virusType-input",
      },
    },
  ],
});
