import { DocTypeType } from "../../api";
import { isDniValid } from "./isDniValid";

const isCipValid = (cip: string): boolean => {
  if (!cip) {
    return false;
  }

  if (/^[A-Z]{4}[0-9]{6}[A-Z0-9]{6}$/g.test(cip)) {
    return true;
  }

  return false;
};

const isPassportValid = (passport: string): boolean => {
  if (!passport || !(passport.length === 9)) {
    return false;
  }

  return true;
};

export const isDocumentValid = (docType: DocTypeType, idValue: string): boolean => {
  switch (docType) {
    case DocTypeType.DNI:
    case DocTypeType.NIE:
      return isDniValid(idValue, docType);
    case DocTypeType.SCS_ID:
      return isCipValid(idValue);
    case DocTypeType.PASSPORT:
      return isPassportValid(idValue);
    default:
      return false;
  }
};
