import styled from "@emotion/styled";
import { Divider } from "@mui/material";
import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  return (
    <Container>
      <Link
        href="https://www.scsalud.es/aviso_legal"
        variant="body2"
        target="_blank"
        rel="noopener"
      >
        {t("footer.legalAdvise")}
      </Link>
      <Divider
        orientation="vertical"
        flexItem
        style={{ marginLeft: 10, marginRight: 10 }}
      />
      <Link
        onClick={() => navigate("/privacy")}
        style={{ cursor: "pointer" }}
        variant="body2"
        target="_blank"
        rel="noopener"
      >
        {t("footer.dataPrivacy")}
      </Link>
    </Container>
  );
};

const Container = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  position: relative;
  bottom: 0;
`;

export default Footer;
