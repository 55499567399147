import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: ["Raleway", "Rubik"].join(","),
  },
  palette: {
    primary: {
      main: "#afd7f3", // For buttons
      light: "white",
    },
    secondary: {
      main: "#1e2749", // For titles
    },
    success: {
      main: "#4de36d",
    },
    error: {
      main: "#fd5866",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        html, body, #root {
          height: 100%;
          image-rendering: -webkit-optimize-contrast;
        }

        // To remove the margin in the last child on a form

        form > div:last-child > div:not(.MuiAutocomplete-root) > .MuiTextField-root {
          margin-bottom: 0px;
        }
      `,
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: "Rubik",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontFamily: "Rubik",
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          fontFamily: "Rubik",
          fontWeight: "500",
          backgroundColor: "#afd7f3",
          color: "white",
          padding: "7px",
          fontSize: "1em",
          textTransform: "none",
        },
      },
    },
  },
});

theme.typography.h1 = {
  fontFamily: "Raleway",
  fontWeight: "700",
  fontSize: "3.5rem",
  color: theme.palette.secondary.main,
};

theme.typography.h2 = {
  fontFamily: "Raleway",
  fontWeight: "600",
  fontSize: "2.5rem",
  color: theme.palette.secondary.main,
  [theme.breakpoints.down("md")]: {
    fontSize: "2.2rem",
  },
};

theme.typography.h3 = {
  fontFamily: "Raleway",
  fontWeight: "600",
  fontSize: "2.5rem",
  color: theme.palette.secondary.main,
  [theme.breakpoints.down("md")]: {
    fontSize: "2rem",
  },
};

theme.typography.h4 = {
  fontFamily: "Raleway",
  fontWeight: "600",
  fontSize: "1.5rem",
  textTransform: "uppercase",
  lineHeight: "23px",
  color: theme.palette.secondary.main,
  [theme.breakpoints.down("md")]: {
    fontSize: "1.3rem",
  },
};

theme.typography.h5 = {
  fontFamily: "Rubik",
  fontWeight: "800",
  fontSize: "1rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "0.9rem",
  },
};

theme.typography.subtitle1 = {
  fontFamily: "Raleway",
  fontWeight: "400",
  fontSize: "1.1rem",
  lineHeight: "19px",
  color: theme.palette.secondary.main,
  [theme.breakpoints.down("md")]: {
    fontSize: "0.9rem",
  },
};

theme.typography.subtitle2 = {
  fontFamily: "Rubik",
  fontWeight: "500",
  fontSize: "1.1rem",
  cursor: "pointer",
  lineHeight: "19px",
  color: theme.palette.primary.main,
  [theme.breakpoints.down("md")]: {
    fontSize: "0.9rem",
  },
};

theme.typography.body1 = {
  fontFamily: "Rubik",
  fontWeight: "400",
  fontSize: "1rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "0.9rem",
  },
};

theme.typography.body2 = {
  fontFamily: "Rubik",
  fontWeight: "400",
  fontSize: "1rem",
  color: theme.palette.primary.light,
  [theme.breakpoints.down("md")]: {
    fontSize: "0.9rem",
  },
};

export type Theme = typeof theme;

export default theme;
