import React, { FC } from "react";
import ReactPhoneInput from "react-phone-input-material-ui";
import { withError } from "@capdilla/react-d-form";
import es from "react-phone-input-2/lang/es.json";
import TextField from "@mui/material/TextField";

const TTextField: FC = React.memo((innerProps) => (
  <TextField variant="outlined" size="small" fullWidth {...innerProps} />
));

const PhonePicker = withError(
  ({ label, onChange, props, value, placeholder, error }) => {
    return (
      <ReactPhoneInput
        localization={es}
        value={value}
        country={"es"}
        placeholder={placeholder || "+34 654 789 852"}
        onChange={onChange}
        component={TTextField}
        {...props}
        inputProps={{ label, error, helperText: error?.content, "data-cy": "phone-number-input" }}
        specialLabel={null}
      />
    );
  },
);
export default PhonePicker;
