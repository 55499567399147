import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import moment from "moment";
import spanishLocale from "moment/locale/es";
import englishLocale from "moment/locale/en-gb";

// Languages
import es from "./lang/es";
import en from "./lang/en";

const navigatorLanguage = window.navigator.language || "es";

i18n.use(initReactI18next).init({
  resources: {
    es: {
      translation: es,
    },
    en: {
      translation: en,
    },
  },

  // Default language to be set
  lng: navigatorLanguage,
  // If the navigator language is not present as a resource
  // We set this language as default
  fallbackLng: "es",

  keySeparator: ".",

  interpolation: {
    escapeValue: false,
  },
});

moment.locale(navigatorLanguage);

i18n.on("languageChanged", (lng) => {
  moment.updateLocale(lng, lng === "es" ? spanishLocale : englishLocale);
});

export default i18n;
