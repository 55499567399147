import { isMobile } from "react-device-detect";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import DataPrivacy from "./screens/DataPrivacy";
import NotMobile from "./screens/NotMobile";
import routes from "./utils/routes";
import ScrollToTop from "./utils/scrollToTop";

import "./locales";

const App = () => {
  if (!isMobile && process.env.REACT_APP_ENV !== "test") {
    if (window.location.pathname.includes("privacy")) {
      <DataPrivacy />;
    } else {
      return <NotMobile />;
    }
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {routes.map(({ component, path }) => (
          <Route key={path} path={path} element={component} />
        ))}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
