import React from "react";
import styled from "@emotion/styled";
import { FC } from "react";
import { Typography } from "@mui/material";

interface LabelProps {
  label: React.ReactNode;
  value: React.ReactNode;
  dataCy?: string;
}

const Label: FC<LabelProps> = React.memo(({ label, value, dataCy }) => {
  return (
    <LabelContainer>
      <Typography variant="h5">{label} </Typography>
      <Typography variant="body1" data-cy={ dataCy } >{value || ""}</Typography>
    </LabelContainer>
  );
});

const LabelContainer = styled("div")(() => ({
  flexDirection: "row",
  alignItems: "center",
  flexWrap: "wrap",
  marginBottom: "10px",
  paddingBottom: "5px",
}));

export default Label;
