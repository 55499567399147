import { FormComponent, withError } from "@capdilla/react-d-form";
import TextField from "@mui/material/TextField";
import { Autocomplete, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

interface AutoCompleteItem {
  label: string;
  code: string | number;
}

interface AutoCompleteFormComponent extends FormComponent {
  data: AutoCompleteItem[];
}

const AutoComplete = withError<AutoCompleteFormComponent>(
  ({ label, onChange, props, value, placeholder, error, data }) => {
    const [t] = useTranslation();

    if (value === null || value === undefined) {
      return null;
    }

    return (
      <Autocomplete
        options={data}
        autoHighlight
        getOptionLabel={(option) => option.label}
        noOptionsText={t("autocomplete.noOption")}
        disableClearable
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 1, flexShrink: 0 } }}
            {...props}
          >
            {option.label}
          </Box>
        )}
        value={data.find((item) => item.code === value)}
        onChange={(_, newValue) => onChange(newValue?.code)}
        isOptionEqualToValue={(option, a) => option.code === a.code}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
            error={!!error}
            label={label}
            placeholder={placeholder}
            helperText={error?.content}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password",
            }}
          />
        )}
      />
    );
  },
);

export default AutoComplete;
