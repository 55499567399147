import { Fields } from "@capdilla/react-d-form";
import { TFunction } from "i18next";
import { DocTypeType, TestResult, TestReportResult } from "../../api";
import theme from "../../utils/theme";

export interface ConfirmTest {
    uuid: string;
    fullName: string;
    docTypeId: DocTypeType;
    idValue: string;
    results: TestReportResult[];
    COVID_19: TestResult;
    FLU_A: TestResult;
    FLU_B: TestResult;
    birthday: string;
    phoneNumber: string;
    nationality: string;
  }

//   type VirusNames = "COVID_19" | "FLU_A" | "FLU_B";

const getColorFromTestResult = (result: any) => {
  switch (result) {
    case TestResult.NEGATIVE:
      return theme.palette.success.main;
    case TestResult.POSITIVE:
      return theme.palette.error.main;
    default:
      return "";
  }
};


export const resultsTypeSelectField = (
  t: TFunction,
  results: Array<TestReportResult>,
): Fields<{ COVID_19: TestResult } | { COVID_19: TestResult, FLU_A: TestResult, FLU_B: TestResult }> => {
  return {fields: (values)=> results.map((itemResult) => {
    return {
      name: itemResult.virus?.name as "COVID_19" ,
      type: "Select",
      data: [
        TestResult.NEGATIVE,
        TestResult.POSITIVE,
        TestResult.UNDEFINED,
      ].map((key) => ({
        value: key,
        label: t(`confirmTest.testResult.${key}`),
        disabled: key === TestResult.UNDEFINED,
        style: {
          color: getColorFromTestResult(key),
        },
      })),
      label: t("confirmTest.result") + " "+ itemResult.virus?.commonName,
      validation: {
        required: true,
        errorMessage: t("confirmTest.errors.validation.result"),
        custom: (confirmedTest) => {
            let result: TestResult = confirmedTest[itemResult.virus?.name as "COVID_19"];
            return (result === TestResult.POSITIVE || result === TestResult.NEGATIVE) ? true : false;
        },
      },
      props: {
        color:
            values[itemResult.virus?.name as "COVID_19"]=== TestResult.NEGATIVE
                ? "success"
                : "error",
        focused: true,
        SelectProps: {
            style: {
                color: getColorFromTestResult(values[itemResult.virus?.name as "COVID_19"]),
            },
        },
        "data-cy": "result-input",
      },
    //   value: itemResult.result, 
    };
  })
}

};
