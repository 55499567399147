/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateTestReportInput = {
  docTypeId: DocTypeType,
  idValue: string,
  fullname: string,
  image: string,
  phoneNumber: string,
  nacionality: string,
  birthday: string,
  virusType: VirusType,
};

export enum DocTypeType {
  DNI = "DNI",
  SCS_ID = "SCS_ID",
  NIE = "NIE",
  PASSPORT = "PASSPORT",
}


export enum VirusType {
  COVID = "COVID",
  COVID_AND_FLU = "COVID_AND_FLU",
}


export type TestReport = {
  __typename: "TestReport",
  id: string,
  docTypeId: string,
  docType: DocType,
  idValue: string,
  results?:  Array<TestReportResult > | null,
  fullname: string,
  status: TestReportStatus,
  uuid: string,
  phoneNumber?: string | null,
  birthday?: string | null,
  nacionality?: string | null,
  createdAt: string,
  updatedAt: string,
  deletedAt?: string | null,
};

export type DocType = {
  __typename: "DocType",
  type: DocTypeType,
};

export type TestReportResult = {
  __typename: "TestReportResult",
  testReportId: string,
  virusId: string,
  virus?: Virus | null,
  result?: TestResult | null,
};

export type Virus = {
  __typename: "Virus",
  id: string,
  name: string,
  commonName: string,
  medicalTerm?: string | null,
  createdAt: string,
  updatedAt: string,
};

export enum TestResult {
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
  UNDEFINED = "UNDEFINED",
}


export enum TestReportStatus {
  CAPTURED = "CAPTURED",
  PROCESSED = "PROCESSED",
  NOT_SENT = "NOT_SENT",
  SENT = "SENT",
  RESEND_NEEDED = "RESEND_NEEDED",
  EDITING_NEEDED = "EDITING_NEEDED",
}


export type CreateWebTestReportInput = {
  image: string,
  phoneNumber: string,
  virusType: VirusType,
};

export type TestReportWithToken = {
  __typename: "TestReportWithToken",
  statusCode: number,
  result: TestResult,
  uuid: string,
  token: string,
};

export type ConfirmTestReportInput = {
  uuid: string,
  results: Array< TestReportConfirmedResult >,
};

export type TestReportConfirmedResult = {
  virusId: string,
  result: TestResultConfirmed,
};

export enum TestResultConfirmed {
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
}


export type ConfirmWebTestReportInput = {
  uuid: string,
  results: Array< TestReportConfirmedResult >,
  data: ConfirmTestReportDataInput,
  additionalData?: AdditionalDataInput | null,
};

export type ConfirmTestReportDataInput = {
  docTypeId: DocTypeType,
  idValue: string,
  fullname: string,
  phoneNumber: string,
  birthday: string,
  nationality: string,
};

export type AdditionalDataInput = {
  symptoms?: string | null,
  contact: boolean,
  vulnerable: VulnerableGroups,
};

export type VulnerableGroups = {
  IMD: boolean,
  EMB: boolean,
  NVC: boolean,
  PSS: boolean,
  CPV: boolean,
};

export type CreateTestReportMutationVariables = {
  input: CreateTestReportInput,
};

export type CreateTestReportMutation = {
  createTestReport:  {
    __typename: "TestReport",
    id: string,
    docTypeId: string,
    docType:  {
      __typename: "DocType",
      type: DocTypeType,
    },
    idValue: string,
    results?:  Array< {
      __typename: "TestReportResult",
      testReportId: string,
      virusId: string,
      virus?:  {
        __typename: "Virus",
        id: string,
        name: string,
        commonName: string,
        medicalTerm?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      result?: TestResult | null,
    } > | null,
    fullname: string,
    status: TestReportStatus,
    uuid: string,
    phoneNumber?: string | null,
    birthday?: string | null,
    nacionality?: string | null,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
  },
};

export type CreateWebTestReportMutationVariables = {
  input: CreateWebTestReportInput,
};

export type CreateWebTestReportMutation = {
  createWebTestReport:  {
    __typename: "TestReport",
    id: string,
    docTypeId: string,
    docType:  {
      __typename: "DocType",
      type: DocTypeType,
    },
    idValue: string,
    results?:  Array< {
      __typename: "TestReportResult",
      testReportId: string,
      virusId: string,
      virus?:  {
        __typename: "Virus",
        id: string,
        name: string,
        commonName: string,
        medicalTerm?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      result?: TestResult | null,
    } > | null,
    fullname: string,
    status: TestReportStatus,
    uuid: string,
    phoneNumber?: string | null,
    birthday?: string | null,
    nacionality?: string | null,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
  },
};

export type UpdateTestReportResultMutationVariables = {
  uuid: string,
  result: TestResult,
  token: string,
  statusCode: number,
};

export type UpdateTestReportResultMutation = {
  updateTestReportResult?:  {
    __typename: "TestReportWithToken",
    statusCode: number,
    result: TestResult,
    uuid: string,
    token: string,
  } | null,
};

export type ConfirmTestReportMutationVariables = {
  input: ConfirmTestReportInput,
};

export type ConfirmTestReportMutation = {
  confirmTestReport:  {
    __typename: "TestReport",
    id: string,
    docTypeId: string,
    docType:  {
      __typename: "DocType",
      type: DocTypeType,
    },
    idValue: string,
    results?:  Array< {
      __typename: "TestReportResult",
      testReportId: string,
      virusId: string,
      virus?:  {
        __typename: "Virus",
        id: string,
        name: string,
        commonName: string,
        medicalTerm?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      result?: TestResult | null,
    } > | null,
    fullname: string,
    status: TestReportStatus,
    uuid: string,
    phoneNumber?: string | null,
    birthday?: string | null,
    nacionality?: string | null,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
  },
};

export type ConfirmWebTestReportMutationVariables = {
  input: ConfirmWebTestReportInput,
};

export type ConfirmWebTestReportMutation = {
  confirmWebTestReport:  {
    __typename: "TestReport",
    id: string,
    docTypeId: string,
    docType:  {
      __typename: "DocType",
      type: DocTypeType,
    },
    idValue: string,
    results?:  Array< {
      __typename: "TestReportResult",
      testReportId: string,
      virusId: string,
      virus?:  {
        __typename: "Virus",
        id: string,
        name: string,
        commonName: string,
        medicalTerm?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      result?: TestResult | null,
    } > | null,
    fullname: string,
    status: TestReportStatus,
    uuid: string,
    phoneNumber?: string | null,
    birthday?: string | null,
    nacionality?: string | null,
    createdAt: string,
    updatedAt: string,
    deletedAt?: string | null,
  },
};

export type MeQuery = {
  me?: string | null,
};
