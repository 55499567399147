import { useNavigate } from "react-router";
import { Typography } from "@mui/material";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";

import instructionsAnimation from "../assets/animations/instructions.json";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: instructionsAnimation,
};

const Home = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const skipAnimation = () => {
    navigate("/capture");
  };

  return (
    <Container>
      <Lottie
        options={defaultOptions}
        height={300}
        eventListeners={[
          {
            eventName: "complete",
            callback: skipAnimation,
          },
        ]}
      />
      <Typography
        variant="subtitle2"
        onClick={skipAnimation}
        style={{ fontSize: "1.3rem" }}
        data-cy="btn-skip"
      >
        {t("home.skipAnimation")}
      </Typography>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export default Home;
