const es = {
  title: "Cañía Ciudadanía",
  datePicker: {
    cancel: "Cancelar",
    clear: "Limpiar",
  },
  autocomplete: {
    noOption: "Opción no encontrada",
  },
  home: {
    skipAnimation: "Saltar animación",
    scanQR:
      "Escanea el código QR usando la cámara de tu móvil para acceder a la aplicación y registrar tu test COVID-19",
  },
  captureTest: {
    captureTestTitle: "Enviar prueba de diagnóstico de COVID-19",
    captureTestSubtitleOCR:
      "Coloca el test en la parte superior de la imagen y tu documento de identidad en la parte inferior",
    captureTestSubtitlePassport: {
      partOne: "Coloca el test en la parte superior de la imagen. ",
      partTwo: "Utilizar sólo si no tienes DNI/NIE o Tarjeta Sanitaria",
    },
    captureTestWithOCR: "Enviar con DNI/NIE/Tarjeta Sanitaria",
    captureTestWithPassport: "Enviar con pasaporte",
    phoneNumber: "Teléfono móvil",
    fullName: "Nombre completo",
    fullNamePlaceholder: "Nombre Apellidos",
    passportScreen: {
      passport: "Pasaporte",
      birthday: "Fecha de nacimiento",
      nationality: "Nacionalidad",
    },
    browseImage: "Buscar imagen",
    camera: {
      test: "Prueba de antígenos",
      idCard: "Tarjeta de identidad",
      noIdentityCard: "No es necesario escanear la tarjeta de identidad",
    },
    captureTestButton: "Capturar foto",
    photoInstructions: {
      partOne: "Realizar foto en un plazo ",
      partTwo: "no superior a 40 minutos ",
      partThree:
        "desde la toma de la muestra. Asegurarse de tomar la foto en una zona con ",
      partFour: "buena exposición de luz",
      partFive: ", evitando hacer sombras.",
      validTestInfo:
        "Recuerde utilizar sólo aquellos tests que hayan sido validados por la CE. Para más información acceda a esta ",
      validTestInfoHref: "nota informativa.",
    },
    testTypes: "Tipo de Test",
    COVID: "Test de COVID-19",
    COVID_AND_FLU: "Test de COVID-19, Gripe A y B",
    errors: {
      validation: {
        fullName: "Debe ser un nombre válido",
        passport: "Debe ser un número de pasaporte válido",
        phoneNumber: "Debe ser un teléfono móvil válido",
        birthday: "Debe ser una fecha válida",
        birthdayMustBeLower: "Debe ser una fecha anterior a hoy",
        nationality: "Debe seleccionar una nacionalidad",
        testType: "Debe seleccionar un tipo de test"
      },
      camera:
        "Hubo un error al cargar la cámara. Asegurate que tengamos los permisos necesarios.",
      image: {
        noImage:
          "Hubo un error al capturar la imagen. Por favor, inténtalo de nuevo",
      },
      onCreateTestReport: {
        INVALID_ID: "El número de documento introducido no es correcto",
        INVALID_NAME: "El nombre introducido no es correcto",
        INVALID_DOCUMENT_TYPE:
          "No pudimos extraer la información de tu documento. Asegúrate de colocar el reverso de tu DNI/NIE o el frente de tu Tarjeta Sanitaria.",
        INVALID_IMAGE:
          "Parece que la imagen enviada no es correcta. Por favor, inténtalo de nuevo.",
        COULD_NOT_UPLOAD_IMAGE:
          "Hubo un problema al procesar la imagen. Por favor, inténtalo de nuevo.",
        COULD_NOT_CREATE_TEST:
          "Lo sentimos, hubo un error y no pudimos generar el test. Por favor, inténtalo de nuevo.",
      },
      onSubscription: {
        timeout:
          "No pudimos completar tu petición. Por favor, inténtalo de nuevo.",
        statusCode:
          "Hubo un error al procesar tu test. Por favor, inténtalo de nuevo.",
        noTestOnImage:
          "No pudimos identificar ningún test en la fotografía. Por favor, inténtalo de nuevo.",
        error:
          "Algo salió mal procesando tu test. Por favor, inténtalo de nuevo.",
      },
      generic: "Lo sentimos, algo ha salido mal. Por favor, inténtalo de nuevo",
    },
  },
  confirmTest: {
    confirmTestTitle: "Enviar prueba de diagnóstico de COVID-19",
    confirmTestSubtitle:
      "Confirme que sus datos y el resultado del test son correctos, si no es así editelos o vuelva a intentarlo",
    result: "Resultado",
    docType: {
      DNI: "DNI",
      NIE: "NIE",
      SCS_ID: "Tarjeta Sanitaria",
      PASSPORT: "Pasaporte",
    },
    testResult: {
      NEGATIVE: "Negativo",
      POSITIVE: "Positivo",
      UNDEFINED: "Indefinido",
    },
    errors: {
      validation: {
        idValue: "Debe ser un número de documento válido",
        result: "El resultado del test debe ser positivo o negativo",
      },
    },
    goBack: "Volver",
    continue: "Continuar",
  },
  final: {
    finalTitle: "¡Gracias, {{name}}!",
    finalSubtitle:
      "Muchas gracias por utilizar Cañía. Pronto nos pondremos en contacto contigo.",
    reference: "Referencia",
    fullname: "Nombre completo",
    docType: {
      DNI: "DNI",
      NIE: "NIE",
      SCS_ID: "Tarjeta sanitaria",
      PASSPORT: "Pasaporte",
    },
    nationality: "Nacionalidad",
    mobilePhone: "Teléfono móvil",
    date: "Fecha",
    result: "Resultado",
    COVID_19: "COVID-19",
    FLU_A: "GRIPE A",
    FLU_B: "GRIPE B",
    resultValue: {
      NEGATIVE: "Negativo",
      POSITIVE: "Positivo",
    },
    goBackButton: "Volver",
  },
  extraInfo: {
    title: "Información adicional",
    subtitle: "A continuación, marque las casillas que correspondan",
    form: {
      yes: "Sí",
      no: "No",
      symptoms: "¿Ha presentado síntomas compatibles?",
      initialSymptomDate: "Fecha de inicio de síntomas",
      contact: "¿Ha tenido contacto con otras personas?",
      vulnerableGroup: "¿Cumple alguna de estas condiciones?",
      markThoseWhichApply: "(Marque todos aquellos que apliquen)",
    },
    vulnerabilityGroups: {
      M65: "Mayor de 65 años",
      IMD: "Inmunodeprimido",
      EMB: "Embarazada",
      NVC: "No vacunado/a",
      PSS: "Personal sanitario y sociosanitario",
      CPV: "Cuidador de personas vulnerables",
      NONE: "Ninguna",
    },
    send: "Enviar",
  },
  footer: {
    legalAdvise: "Aviso legal",
    dataPrivacy: "Protección de datos",
  },
  dataPrivacy: {},
  404: {
    notFound: "Página no encontrada",
    notFoundDescription: "Lo sentimos, la página que estás buscando no existe",
    back: "Volver",
  },
};

export default es;
