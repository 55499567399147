import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterMoment from "@mui/lab/AdapterMoment";
import { withError } from "@capdilla/react-d-form";
import TextField from "@mui/material/TextField";
import MuiDatePicker from "@mui/lab/DatePicker";
import { useTranslation } from "react-i18next";

const DatePicker = withError(
  ({ label, onChange, props, value, placeholder, error }) => {
    const [t] = useTranslation();

    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MuiDatePicker
          label={label}
          value={value}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              size="small"
              label={label}
              placeholder={placeholder}
              {...params}
              error={!!error}
              helperText={error?.content}
              data-cy="date-picker"
            />
          )}
          cancelText={t("datePicker.cancel")}
          clearText={t("datePicker.clear")}
          {...props}
        />
      </LocalizationProvider>
    );
  },
);

export default DatePicker;
