import { FC, useState } from "react";
import { TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { styled } from "@mui/material/styles";
import toBase64 from "../utils/toBase64";

interface FilePickerProps {
  onFileLoad: (file: string) => void;
}

const FilePicker: FC<FilePickerProps> = ({ onFileLoad }) => {
  const [fileName, setFileName] = useState<string>();
  const [t] = useTranslation();

  const onChangeFileLoad = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { files } = event.target;

    if (!files) {
      return Promise.reject();
    }

    const base64File = await toBase64(files[0]);
    onFileLoad(base64File);
    setFileName(files[0].name);
  };

  return (
    <Container>
      <TextField
        type="file"
        inputProps={{
          accept: "image/png, image/jpeg",
          "data-cy": "file-picker",
        }}
        style={{ display: "none" }}
        onChange={onChangeFileLoad}
      />
      <FileUploadIcon htmlColor={"white"} />
      <Typography variant="body2" data-cy="file-picker-label">
        {fileName || t("captureTest.browseImage")}
      </Typography>
    </Container>
  );
};

const Container = styled("label")(({ theme }) => ({
  width: "100%",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  padding: "5px",
  justifyContent: "center",
  backgroundColor: theme.palette.primary.main,
  borderRadius: "0 0 5px 5px",
}));

export default FilePicker;
