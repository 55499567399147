import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { QRCode } from "react-qrcode-logo";
import { useTranslation } from "react-i18next";
import Logo from "../../components/Logo";
import Header from "./Header";
import React from "react";

const NotMobile = () => {
  const [t] = useTranslation();

  return (
    <React.Fragment>
      <Header />

      <Container>
        <SubContainer>
          <QRCode
            fgColor="black"
            quietZone={10}
            size={280}
            value={process.env.REACT_APP_ENV_URL || "https://www.scsalud.es/"}
          />
          <DescriptionContainer>
            <LogoContainer>
              <Logo />
            </LogoContainer>

            <TextContainer>
              <Typography variant="h2">{t("title")}</Typography>
              <Typography
                variant="body1"
                sx={{ fontSize: "28px", paddingTop: "15px" }}
              >
                {t("home.scanQR")}
              </Typography>
            </TextContainer>
          </DescriptionContainer>
        </SubContainer>
      </Container>
    </React.Fragment>
  );
};

const Container = styled("div")(({ theme }) => ({
  minWidth: 300,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "85vh",
}));

const SubContainer = styled("div")(({ theme }) => ({
  marginBottom: "40px",
  maxWidth: "50vw",
  alignItems: "center",
  display: "inline-flex",
  justifyContent: "center",
  flexDirection: "column",
}));

const DescriptionContainer = styled("div")(() => ({
  display: "inline-flex",
  flexDirection: "row",
  paddingTop: "12vh",
  justifyContent: "center",
  alignItems: "center",
}));

const LogoContainer = styled("div")(() => ({
  paddingRight: "60px",
}));

const TextContainer = styled("div")(() => ({
  display: "inline-flex",
  flexDirection: "column",
}));

export default NotMobile;
