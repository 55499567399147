/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTestReport = /* GraphQL */ `
  mutation CreateTestReport($input: CreateTestReportInput!) {
    createTestReport(input: $input) {
      id
      docTypeId
      docType {
        type
      }
      idValue
      results {
        testReportId
        virusId
        virus {
          id
          name
          commonName
          medicalTerm
          createdAt
          updatedAt
        }
        result
      }
      fullname
      status
      uuid
      phoneNumber
      birthday
      nacionality
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const createWebTestReport = /* GraphQL */ `
  mutation CreateWebTestReport($input: CreateWebTestReportInput!) {
    createWebTestReport(input: $input) {
      id
      docTypeId
      docType {
        type
      }
      idValue
      results {
        testReportId
        virusId
        virus {
          id
          name
          commonName
          medicalTerm
          createdAt
          updatedAt
        }
        result
      }
      fullname
      status
      uuid
      phoneNumber
      birthday
      nacionality
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const updateTestReportResult = /* GraphQL */ `
  mutation UpdateTestReportResult(
    $uuid: ID!
    $result: TestResult!
    $token: String!
    $statusCode: Int!
  ) {
    updateTestReportResult(
      uuid: $uuid
      result: $result
      token: $token
      statusCode: $statusCode
    ) {
      statusCode
      result
      uuid
      token
    }
  }
`;
export const confirmTestReport = /* GraphQL */ `
  mutation ConfirmTestReport($input: ConfirmTestReportInput!) {
    confirmTestReport(input: $input) {
      id
      docTypeId
      docType {
        type
      }
      idValue
      results {
        testReportId
        virusId
        virus {
          id
          name
          commonName
          medicalTerm
          createdAt
          updatedAt
        }
        result
      }
      fullname
      status
      uuid
      phoneNumber
      birthday
      nacionality
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
export const confirmWebTestReport = /* GraphQL */ `
  mutation ConfirmWebTestReport($input: ConfirmWebTestReportInput!) {
    confirmWebTestReport(input: $input) {
      id
      docTypeId
      docType {
        type
      }
      idValue
      results {
        testReportId
        virusId
        virus {
          id
          name
          commonName
          medicalTerm
          createdAt
          updatedAt
        }
        result
      }
      fullname
      status
      uuid
      phoneNumber
      birthday
      nacionality
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
