import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { FC } from "react";

interface CameraOverlayProps {
  isIdCardRequired: boolean;
}

const CameraOverlay: FC<CameraOverlayProps> = ({ isIdCardRequired }) => {
  const [t] = useTranslation();

  return (
    <Container>
      <TestContainer isIdCardRequired={isIdCardRequired}>
        <Typography variant="body2" style={centeredText}>
          {t("captureTest.camera.test")}
        </Typography>
        <SpacerView />
      </TestContainer>
      {isIdCardRequired && (
        <div style={alignCenter}>
          <Typography variant="body2" style={centeredText}>
             {t("captureTest.camera.idCard")}
          </Typography>
        </div>
      )}
    </Container>
  );
};

const centeredText: React.CSSProperties = { textAlign: "center" };
const alignCenter: React.CSSProperties = {
  display: "flex",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
};

const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`;

const SpacerView = styled.div`
  height: 60px;
  width: 60px;
  border: 3px solid #96c0e85c;
`;

const TestContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${(props: { isIdCardRequired: boolean }) =>
    props.isIdCardRequired ? "0.5" : "0.335"};
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-color: #96c0e85c;
  align-items: center;
  justify-content: center;
`;

export default CameraOverlay;
