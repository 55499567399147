import React, { useContext, useState } from "react";
import Webcam from "react-webcam";
import styled from "@emotion/styled";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { styled as muiStyled } from "@mui/material/styles";

import CameraOverlay from "./CameraOverlay";
import { CaptureTestContext } from "./context";
import { CaptureTestScreenTypes } from "./util";
import ErrorAlert from "../../components/ErrorAlert";
import FilePicker from "../../components/FilePicker";

const videoConstraints: MediaStreamConstraints["video"] = {
  facingMode: { ideal: "environment" },
  width: 800,
  height: 600,
};

const Camera = () => {
  const { camera, image, activeScreen, setIsFileUploaded } =
    useContext(CaptureTestContext);
  const [error, setError] = useState<Error>();
  const [loading, setLoading] = useState(true);

  const isIdCardRequired = activeScreen === CaptureTestScreenTypes.OCR;

  const onFileLoad = (file: string) => {
    if (camera && camera.current) {
      setIsFileUploaded(true);
      camera.current.getScreenshot = () => {
        return file;
      };
    }
  };

  if (image) {
    return (
      <Container isIdCardRequired={isIdCardRequired}>
        <Image src={image} />
      </Container>
    );
  }

  return (
    <React.Fragment>
      <Container isIdCardRequired={isIdCardRequired}>
        <CameraContainer>
          <Webcam
            audio={false}
            height={"100%"}
            ref={camera}
            screenshotFormat="image/jpeg"
            forceScreenshotSourceSize
            onUserMedia={() => setLoading(false)}
            onUserMediaError={() => {
              setLoading(false);
              setError(new Error("captureTest.errors.camera"));
            }}
            screenshotQuality={1}
            width={"100%"}
            videoConstraints={videoConstraints}
            style={{
              objectFit: "cover",
            }}
          />
          <CameraOverlay isIdCardRequired={isIdCardRequired} />
          <TBackdrop
            sx={{ color: "#fff", backgroundColor: "lightgray" }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </TBackdrop>
        </CameraContainer>
        <ErrorAlert error={error} setError={setError} />
      </Container>
      {!process.env.REACT_APP_ENV ||
      process.env.REACT_APP_ENV === "dev" ||
      process.env.REACT_APP_ENV === "test" ? (
        <BrowseFileContainer>
          <FilePicker onFileLoad={onFileLoad} />
        </BrowseFileContainer>
      ) : null}
    </React.Fragment>
  );
};

interface ComponentProps {
  isIdCardRequired: boolean;
}

const Container = styled.div`
  height: ${(props: ComponentProps) =>
    props.isIdCardRequired ? "300px" : "102px"};
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
`;

const CameraContainer = styled.div`
  position: relative;
  height: 300px;
  overflow: hidden;
  background-color: lightgray;
`;

const BrowseFileContainer = muiStyled("div")(({ theme }) => ({
  marginBottom: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.primary.main,
}));

const TBackdrop = styled(Backdrop)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 23;
`;

const Image = styled.img`
  height: 300px;
  border-radius: 5px;
  width: 100%;
  object-fit: cover;
`;

export default Camera;
