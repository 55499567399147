import { useContext } from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";

import Form from "../../components/Form";
import { CaptureTestContext } from "./context";
import { phoneNumberField, virusTypeSelectField } from "./util";

const CaptureWithOCR = () => {
  const { userData, setUserData, showValidation } =
    useContext(CaptureTestContext);
  const [t] = useTranslation();

  return (
    <Container>
      <Form
        fields={[
          phoneNumberField(t), 
          virusTypeSelectField(t)
        ]}
        onFormChange={setUserData}
        showValidation={showValidation}
        executeChangeOnBlur={false}
        defaultState={userData?.data}
      />
    </Container>
  );
};

const Container = styled.div`
  margin: 3vh 0px;
`;

export default CaptureWithOCR;
