import React, { useState } from "react";
import PropTypes from "prop-types";
import LoadingButton, {
  LoadingButtonProps as ButtonProps,
} from "@mui/lab/LoadingButton";

interface Props extends ButtonProps {
  onClick?: (e: React.MouseEvent) => Promise<void>;
}

const Button: React.FunctionComponent<Props> = ({
  onClick,
  children,
  ...restProps
}) => {
  const [isLoading, toggle] = useState(false);

  return (
    <LoadingButton
      data-testid="loading-button"
      loading={isLoading}
      onClick={(e) => {
        toggle(true);
        onClick && onClick(e).finally(() => toggle(false));
      }}
      variant="contained"
      disableElevation
      fullWidth
      {...restProps}
    >
      {children}
    </LoadingButton>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
};

export default Button;
