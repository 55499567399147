import React, { FC } from "react";
import styled from "@emotion/styled";

interface Props extends React.HTMLProps<HTMLImageElement> {}

const Logo: FC<Props> = ({ as, crossOrigin, ...props }) => {
  return <CaniaPic src="./logo512.png" {...props} />;
};

const CaniaPic = styled.img`
  height: 100px;
  border-radius: 22px;
`;

export default Logo;
