import { FormData } from "@capdilla/react-d-form";
import React from "react";
import Webcam from "react-webcam";
import { CaptureTestScreenTypes, UserData } from "./util";

interface IState {
  userData: FormData<UserData> | undefined;
  setUserData: React.Dispatch<
    React.SetStateAction<FormData<UserData> | undefined>
  >;
  showValidation: boolean;
  camera: React.MutableRefObject<Webcam | null> | undefined;
  image: string | undefined;
  activeScreen: CaptureTestScreenTypes;
  setActiveScreen: React.Dispatch<React.SetStateAction<CaptureTestScreenTypes>>;
  isFileUploaded: boolean;
  setIsFileUploaded: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultValue: IState = {
  userData: undefined,
  setUserData: () => null,
  showValidation: false,
  camera: undefined,
  image: undefined,
  activeScreen: CaptureTestScreenTypes.OCR,
  setActiveScreen: () => null,
  isFileUploaded: false,
  setIsFileUploaded: () => null,
};

export const CaptureTestContext = React.createContext(defaultValue);
