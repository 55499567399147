import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const Header = () => {
  return (
    <Container>
      <TitleContainer>
        <Image src="./logo-consejeria.png" />
        <Image src="./logo-scs.png" />
        <Title>
          <Typography variant="subtitle1">
            Servicio Cántabro de Salud
          </Typography>
          <Typography variant="subtitle2">Cañía Ciudadanía</Typography>
        </Title>
      </TitleContainer>
      <ImageContainer>
        <ShieldLogo src="./escudo-cabecera.png" />
      </ImageContainer>
    </Container>
  );
};

const Container = styled.header`
  height: 15vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 3;
  padding: 20px;
`;

const Image = styled.img`
  max-height: 60px;
  margin: 10px;
`;

const Title = styled.div`
  margin: 10px;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ImageContainer = styled.div`
  flex: 2;
  display: flex;
  justify-content: end;
`;

const ShieldLogo = styled.img`
  height: 100%;
  max-height: 15vh;
  margin-right: 10px;
`;

export default Header;
