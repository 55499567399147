import { IDictionary } from ".";

const en: IDictionary = {
  title: "Cañía Ciudadanía",
  datePicker: {
    cancel: "Cancel",
    clear: "Clear",
  },
  autocomplete: {
    noOption: "Option not found",
  },
  home: {
    skipAnimation: "Skip animation",
    scanQR:
      "Scan the QR code using your mobile camera to access the application and register your COVID-19 test",
  },
  captureTest: {
    captureTestTitle: "Send COVID-19 diagnostic testing",
    captureTestSubtitleOCR:
      "Place the test in the upper part of the image and your ID in the lower part",
    captureTestSubtitlePassport: {
      partOne: "Place the test in the upper part of the image. ",
      partTwo: "Use it only if you don't have DNI/NIE or Tarjeta Sanitaria",
    },
    captureTestWithOCR: "Send with DNI/NIE/Tarjeta Sanitaria",
    captureTestWithPassport: "Send with passport",
    phoneNumber: "Phone number",
    fullName: "Full name",
    fullNamePlaceholder: "Name Surnames",
    passportScreen: {
      passport: "Passport",
      birthday: "Birthday",
      nationality: "Nationality",
    },
    browseImage: "Browse image",
    camera: {
      test: "Place the test here",
      idCard: "ID card",
      noIdentityCard: "It is not necessary to scan an ID",
    },
    captureTestButton: "Capture photo",
    photoInstructions: {
      partOne: "Take a photo within a period ",
      partTwo: "not exceeding 40 minutes ",
      partThree:
        "from the taking of the sample. Make sure to take the photo in an area with ",
      partFour: "good light exposure",
      partFive: ", avoiding making shadows.",
      validTestInfo:
        "Remember to use only those tests that have been validated by the EC. For more information, access this ",
      validTestInfoHref: "informative note.",
    },
    testTypes: "Test Type",
    COVID: "COVID-19 Test",
    COVID_AND_FLU: "COVID-19 Test, Flu A and B",
    errors: {
      validation: {
        fullName: "Must be a valid name",
        passport: "Must be a valid passport number",
        phoneNumber: "Must be a valid phone number",
        birthday: "Must be a valid date",
        birthdayMustBeLower: "Must be a date before today",
        nationality: "Must select a nationality",
        testType: "Must select a test type"
      },
      camera:
        "There was an error loading your camera. Make sure we have the needed permissions.",
      image: {
        noImage: "There was an error taking the picture. Please, try again.",
      },
      onCreateTestReport: {
        INVALID_ID: "The ID number sent is invalid",
        INVALID_NAME: "The name sent is invalid",
        INVALID_DOCUMENT_TYPE:
          "We were unable to extract the information from your document. Make sure to put the back of your DNI / NIE or the front of your Health Card.",
        INVALID_IMAGE:
          "It seems that the sent image is incorrect. Please, try again.",
        COULD_NOT_UPLOAD_IMAGE:
          "Something went wrong processing the image. Please, try again.",
        COULD_NOT_CREATE_TEST:
          "Sorry, something went wrong and we couldn't create the test. Please, try again.",
      },
      onSubscription: {
        timeout: "We couldn't complete your request. Please, try again",
        statusCode:
          "Something went wrong processing your test. Please, try again.",
        noTestOnImage:
          "We couldn't detect a test in the photo. Please, try again",
        error: "Something went wrong processing your test. Please, try again.",
      },
      generic: "Oops! Something went wrong! Please try again",
    },
  },
  confirmTest: {
    confirmTestTitle: "Send COVID-19 diagnostic testing",
    confirmTestSubtitle:
      "Confirm that your personal data and the test result are correct. You may edit them or try again",
    result: "Result",
    docType: {
      DNI: "DNI",
      NIE: "NIE",
      SCS_ID: "Tarjeta Sanitaria",
      PASSPORT: "Passport",
    },
    testResult: {
      NEGATIVE: "Negative",
      POSITIVE: "Positive",
      UNDEFINED: "Undefined",
    },
    errors: {
      validation: {
        idValue: "Must be a valid id",
        result: "The test result must be positive or negative",
      },
    },
    goBack: "Go back",
    continue: "Continue",
  },
  final: {
    finalTitle: "Thank you, {{name}}!",
    finalSubtitle: "Thanks for using Cañía. We'll be contacting with you soon.",
    reference: "Reference",
    fullname: "Full name",
    COVID_19: "COVID-19",
    FLU_A: "FLU A",
    FLU_B: "FLU B",
    docType: {
      DNI: "DNI",
      NIE: "NIE",
      SCS_ID: "Tarjeta sanitaria",
      PASSPORT: "Passport",
    },
    nationality: "Nationality",
    mobilePhone: "Mobile phone",
    date: "Date",
    result: "Result",
    resultValue: {
      NEGATIVE: "Negative",
      POSITIVE: "Positive",
    },
    goBackButton: "Go Back",
  },
  extraInfo: {
    title: "Additional Information",
    subtitle: "If applies, fill the information below",
    form: {
      yes: "Yes",
      no: "No",
      symptoms: "Have you presented compatible symptoms?",
      initialSymptomDate: "Symptoms initial date",
      contact: "Have you had contact with other people?",
      vulnerableGroup: "Do you meet any of these conditions?",
      markThoseWhichApply: "(Check all that apply)",
    },
    vulnerabilityGroups: {
      M65: "Over 65 years old",
      IMD: "Immunosuppressed",
      EMB: "Pregnant",
      NVC: "Not vaccinated",
      PSS: "Health and social health personnel",
      CPV: "Caregiver of vulnerable people",
      NONE: "None of the above",
    },
    send: "Send",
  },
  footer: {
    legalAdvise: "Legal advise",
    dataPrivacy: "Data privacy",
  },
  dataPrivacy: {},
  404: {
    notFound: "Page not found",
    notFoundDescription:
      "We're sorry, we couldn't find the page that you're looking for",
    back: "Go back",
  },
};

export default en;
