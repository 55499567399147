import { FormComponent, withError } from "@capdilla/react-d-form";
import TextField from "@mui/material/TextField";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";

interface SelectItem extends MenuItemProps {
  label: string;
  value: string | number;
}

interface SelectFormComponent extends FormComponent {
  data: SelectItem[];
}

const Select = withError<SelectFormComponent>(
  ({ label, onChange, props, value, placeholder, error, data }) => {
    if (value === null || value === undefined) {
      return null;
    }

    return (
      <TextField
        variant="outlined"
        size="small"
        select
        fullWidth
        error={!!error}
        label={label}
        placeholder={placeholder}
        onChange={(event) => onChange(event.target.value)}
        helperText={error?.content}
        value={value}
        {...props}
      >
        {data.map(
          ({ label: selectLabel, value: selectValue, ...selectProps }) => (
            <MenuItem key={selectValue} value={selectValue} {...selectProps}>
              {selectLabel}
            </MenuItem>
          ),
        )}
      </TextField>
    );
  },
);

export default Select;
