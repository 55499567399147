import styled from "@emotion/styled";
import moment from "moment";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import Form from "../../components/Form";
import nationalities from "../../utils/nationalities.json";
import isNameValid from "../../utils/validators/isNameValid";
import { CaptureTestContext } from "./context";
import { phoneNumberField, virusTypeSelectField} from "./util";

const CaptureWithPassport = () => {
  const { userData, setUserData, showValidation } =
    useContext(CaptureTestContext);
  const [t] = useTranslation();

  return (
    <Container>
      <Form
        fields={[
          {
            fields: [
              {
                name: "fullName",
                type: "Input",
                label: t("captureTest.fullName"),
                validation: {
                  required: true,
                  custom: ({ fullName }) => ({
                    valid: fullName ? isNameValid(fullName) : false,
                    errorMessage: t("captureTest.errors.validation.fullName"),
                  }),
                },
                props: {
                  placeholder: t("captureTest.fullNamePlaceholder"),
                  "data-cy": "fullname-input",
                  inputProps: {
                    maxLength: 150,
                  },
                },
              },
            ],
          },
          {
            fields: [
              {
                name: "idValue",
                type: "Input",
                label: t("captureTest.passportScreen.passport"),
                validation: {
                  required: true,
                  errorMessage: t("captureTest.errors.validation.passport"),
                  custom: (values) => {
                    if (values.idValue && values.idValue.length !== 9) {
                      return {
                        valid: false,
                        errorMessage: t(
                          "captureTest.errors.validation.passport",
                        ),
                      };
                    }

                    return true;
                  },
                },
                props: {
                  "data-cy": "passport-input",
                },
              },
            ],
          },
          {
            fields: [
              {
                name: "nationality",
                type: "Autocomplete",
                data: nationalities,
                label: t("captureTest.passportScreen.nationality"),
                validation: {
                  required: true,
                  errorMessage: t("captureTest.errors.validation.nationality"),
                },
              },
            ],
          },
          {
            fields: [
              {
                name: "birthday",
                type: "DatePicker",
                label: t("captureTest.passportScreen.birthday"),
                validation: {
                  required: true,
                  errorMessage: t("captureTest.errors.validation.birthday"),
                  custom: (values) => {
                    if (
                      values?.birthday &&
                      new Date() <= new Date(values?.birthday)
                    ) {
                      return {
                        valid: false,
                        errorMessage: t(
                          "captureTest.errors.validation.birthdayMustBeLower",
                        ),
                      };
                    }

                    return true;
                  },
                },
                props: {
                  maxDate: moment(),
                },
              },
            ],
          },
          phoneNumberField(t),
          virusTypeSelectField(t)
        ]}
        onFormChange={setUserData}
        showValidation={showValidation}
        executeChangeOnBlur={false}
        defaultState={userData?.data}
      />
    </Container>
  );
};

const Container = styled.div`
  margin: 3vh 0px;
`;

export default CaptureWithPassport;
